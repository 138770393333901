
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Settings, DateTime} from 'luxon';

    import TuvHeader from '@/components/layout/TheHeader.vue';
    import TuvDrawer from '@/components/layout/TheDrawer.vue';
    import TuvDrawerLeft from '@/components/layout/TheDrawerLeft.vue';
    import TuvDrawerNotification from '@/components/layout/TheDrawerNotification.vue';
    import Maintenance from '@/views/Maintenance.vue';

    import {
        AccountModule,
        AgendaModule,
        AnimalModule,
        BusinessHourModule,
        ClientModule,
        EventModule,
        LayoutModule,
        NotificationModule,
        OrganizationModule,
        ParaveterinarianModule,
        ReasonModule,
        RegistrationModule,
        SnackbarModule,
        VeterinarianModule,
    } from '@/store/modules';

    import {
        IOrganization,
    } from '@/types';

    const snackbarNamespace = namespace('snackbar');
    const organizationNamespace = namespace('organization');

    @Component({
        components: {
            TuvHeader,
            TuvDrawer,
            TuvDrawerLeft,
            TuvDrawerNotification,
            Maintenance
        },
    })
    export default class App extends Vue {
        public context = process.env.VUE_APP_CONTEXT || null;
        public deleteExpiredNotificationsInterval: number | null | any = null;
        public maintenanceTime = process.env.VUE_APP_CONTEXT_TIMER;
        public remainingTime: number = Number(0);
        public remaningSnack: boolean = true;

        @snackbarNamespace.Getter('showSnackbar')
        public showSnackbarGetter!: boolean;

        @snackbarNamespace.Getter('showUpdateSnackbar')
        public showUpdateSnackbarGetter!: boolean;

        @snackbarNamespace.Getter('snackbarMessage')
        public snackbarMessage!: string;

        @snackbarNamespace.Getter('snackbarColor')
        public snackbarColor!: string;

        @snackbarNamespace.Action('hide')
        public hideSnackbar!: () => void;

        @snackbarNamespace.Action('hideUpdate')
        public hideUpdateSnackbar!: () => void;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get showSnackbar() {
            return this.showSnackbarGetter;
        }

        set showSnackbar(value: boolean) {
            if (!value) {
                this.hideSnackbar();
            }
        }

        get showUpdateSnackbar() {
            return this.showUpdateSnackbarGetter;
        }

        set showUpdateSnackbar(value: boolean) {
            if (!value) {
                this.hideUpdateSnackbar();
            }
        }

        public refresh() {
            window.location.reload();
        }

        private beforeDestroy() {
          if (this.deleteExpiredNotificationsInterval) {
            clearInterval(this.deleteExpiredNotificationsInterval);
          }
        }

        private created() {
            const animalModule = getModule(AnimalModule, this.$store);
            const notificationModule = getModule(NotificationModule, this.$store);
            const organizationModule = getModule(OrganizationModule, this.$store);
            const reasonModule = getModule(ReasonModule, this.$store);
            const veterinarianModule = getModule(VeterinarianModule, this.$store);
            const isSamsungBrowser = navigator.userAgent.indexOf('SamsungBrowser') > - 1;

            // Assume `this.timezone` is defined somewhere in your component or class.
            let timezone = null;

            // Set timezone
            if (this.loggedOrganization) {
                if (this.loggedOrganization.timezone) {
                    timezone = this.loggedOrganization.timezone;
                } else {
                    organizationModule
                        .fetchOrganization(this.loggedOrganization.id)
                        .then((organization: IOrganization) => {
                            timezone = organization.timezone;
                        });
                }
            }

            // Clear expired notifications every 5min
            this.deleteExpiredNotificationsInterval = setInterval(() => {
              notificationModule.deleteExpiredNotifications();
            }, 300000);

            // Seems like Samsung Browser can't properly detect the correct locale
            if (isSamsungBrowser) {
                Settings.defaultLocale = navigator.language;
            }

            // Load initial data
            animalModule.fetchTypes();
            animalModule.fetchSpecies();
            animalModule.fetchBreeds();
            organizationModule.fetchTypes();
            reasonModule.fetchPublicReasons();
            veterinarianModule.fetchSpecialties();

            // Ugly fix - Force store modules creation
            getModule(AccountModule, this.$store);
            getModule(AgendaModule, this.$store);
            getModule(BusinessHourModule, this.$store);
            getModule(ClientModule, this.$store);
            getModule(EventModule, this.$store);
            getModule(LayoutModule, this.$store);
            getModule(ParaveterinarianModule, this.$store);
            getModule(RegistrationModule, this.$store);
            getModule(SnackbarModule, this.$store);
        }

        public mounted() {
            if (this.context === 'maintenance' && this.remainingTime >= 0) {
                this.startTimerReminder();
            }
        }

        public startTimerReminder() {
            this.timerReminder();
            setTimeout(() => {
                if (this.remainingTime > 0) {
                    this.startTimerReminder();
                }
            }, 5000);
        }

        public timerReminder() {
            const currentDate = new Date();
            const maintenanceTime = new Date(this.maintenanceTime);
            const differenceInMillis = maintenanceTime.getTime() - currentDate.getTime();
            this.remainingTime = Math.floor(differenceInMillis / 1000 / 60);

            if (this.remainingTime <= 0) {
                this.remainingTime = 0;
            }
        }
    }
